import React from 'react';
import { Link as RouterLink } from 'gatsby';
// import { Link as RouterLink } from 'react-router-dom';
// import { graphql } from 'react-apollo';
// import gql from 'graphql-tag';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Icon from '@material-ui/core/Icon';
import PlaceIcon from '@material-ui/icons/Place';
import PersonIcon from '@material-ui/icons/Person';
import VideocamIcon from '@material-ui/icons/Videocam';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import CreateIcon from '@material-ui/icons/Create';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const PLACES_PER_PAGE = 2;
const backgroundImage = 'https://media.graphcms.com/resize=w:1080,fit:crop/monochrome/lDogv3iQkySylI0BtYcG';
// const backgroundImage = 'https://media.graphcms.com/resize=w:1080,fit:crop/sepia=tone:80/torn_edges=spread:[1,10]/vyl1yGJUSgiYbarZHqJd';
// const backgroundImage = 'https://source.unsplash.com/tzQkuviIuHU';
//const backgroundImage = 'https://source.unsplash.com/NMk1Vggt2hg';

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
}));

export default function Home() {
  const classes = useStyles();

  return (
    <Layout>
      
      <SEO />

      <Paper className={classes.mainFeaturedPost} style={{ backgroundImage: `url(${backgroundImage})` }}>
        {/* Increase the priority of the hero background image */}
        {<img style={{ display: 'none' }} src={backgroundImage} alt={'Welcome to Spookt'} />}
        <div className={classes.overlay} />
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.mainFeaturedPostContent}>
              <Typography variant="h5" color="inherit" paragraph>
                Discover the spooky and haunted world we live in.
              </Typography>
              <Link variant="subtitle1"
                component={RouterLink}
                to={'/about'}>
                Learn more about our mission
              </Link>
            </div>
          </Grid>
        </Grid>
      </Paper>

      <Typography variant="h4">
        Explore Spookt
      </Typography>
      <Grid container spacing={1}>
        {renderExploreCard('Places', 'Locations known to be haunted', <PlaceIcon />, '/places')}
        {renderExploreCard('Lore', 'Stories of ghosts and the macabre', <FontAwesomeIcon icon="scroll" />, '/lore')}
        {renderExploreCard('People', 'The departed who haunt us', <PersonIcon />, '/people')}
        {renderExploreCard('Investigations', 'Attempts to capture proof of hauntings', <VideocamIcon />, '/investigations')}
        {renderExploreCard('Books', 'Publications with more information', <MenuBookIcon />, '/books')}
        {renderExploreCard('Ghost Tours', 'People that will take you on a ghost adventure', <FontAwesomeIcon icon="ghost" />, '/tourcompanies')}
      </Grid>

    </Layout>
  );
};

function renderExploreCard(type, description, icon, link) {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card elevation={1}>
        <CardActionArea component={RouterLink} to={link} >
          <CardHeader
            avatar={<Avatar>{icon}</Avatar>}
            title={type}
            subheader={description}
          />
        </CardActionArea>
      </Card>
    </Grid>
  )
};